<!-- @format -->
<script lang="ts">
  
  export let name: any= '';
  export let link: any= '';
  export let is_active: boolean | '' = false;

  let title = name;
  let href = link;

  let className = '';
  export { className as class };

</script>

{#if !href}
<!-- svelte-ignore a11y-click-events-have-key-events -->
  <span class={className} class:active={is_active} {title}>
    <slot>{@html title}</slot>
  </span>
{:else}
  <a class={className} class:active={is_active} {href} {title}>
    <slot>{@html title}</slot>
  </a>
{/if}

<style lang="scss">
  a,
  span {
    display: inline-block;
    padding: 16px 0.5vw;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    @media screen and (min-width: 1024px) {
      padding: 16px 0.5vw 16px 1vw;
    }

    @media screen and (min-width: 1440px) {
      padding: 16px 4px 16px 25px;
    }

    &:hover,
    &.active {
      font-weight: 600;
      letter-spacing: 1.3px;
    }

    /* 
      The pseudo element has the same content and hover style, so it pre-sets the width of 
      the element and visibility: hidden hides the pseudo element from actual view. 
    */
    &::before {
      display: block;
      content: attr(title);
      height: 0;
      font-weight: 600;
      letter-spacing: 1.3px;
      overflow: hidden;
      visibility: hidden;
    }
  }
</style>
