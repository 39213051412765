<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { slide } from 'svelte/transition';
  import NavLink from './NavLink.svelte';
  import DropdownMenu from './DropdownMenu.svelte';

  export let request_store_key: StoresKey;
  export let nav_item: NavigationMenuItem;
  export let wrapped_by_dropdown = false;
  export let toggle_dropdown:any = null;

  const { site_page, i18nOf, reverse_router } = getStoresContext(request_store_key);

  let toggle_submenu = false;

  $: current = $site_page && $site_page.friendlyUrlPath;
  $: submenu_id = 'submenu-' + nav_item.id;

  $: {
    if (toggle_dropdown) {
      toggle_submenu = $toggle_dropdown == submenu_id;
    }
  }
  $: props = {
    name: nav_item.name_i18n ? i18nOf(nav_item.name_i18n) : nav_item.name,
    link: nav_item.link_i18n? $reverse_router.routePage(nav_item.link) : nav_item.link,
    is_active: isActive(nav_item)
  };
  
  function isActive(nav_item: NavigationMenuItem) {
    return current == nav_item.link;
  }
  function toggle() {
    toggle_submenu = !toggle_submenu;
    if (toggle_dropdown) {
      toggle_submenu ? ($toggle_dropdown = submenu_id) : ($toggle_dropdown = null);
    }
  }
</script>

{#if nav_item.navigationMenuItems && nav_item.navigationMenuItems.length}
  <div class="nav-item with-submenu" class:wrapped_by_dropdown>
    <slot {nav_item}>
      <NavLink class="nav-item-link" {...props} on:click={toggle} />
      <button on:click={toggle} aria-controls={submenu_id} aria-expanded={toggle_submenu}>
        <img
          class="icon"
          class:rotate={toggle_submenu}
          src="/theme/mylly2023/images/arrow-down.svg"
          alt="Toggle submenu"
        />
      </button>
    </slot>
    {#if toggle_submenu}
      <div
        class="sub-menu"
        id={submenu_id}
        in:slide={{ duration: 200 }}
        out:slide={{ duration: 200 }}
      >
        <DropdownMenu nav_items={nav_item.navigationMenuItems} {request_store_key} />
      </div>
    {/if}
  </div>
{:else}
  <NavLink class="nav-item" {...props} />
{/if}

<style lang="scss">
  .with-submenu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      padding: 8px 16px 8px 4px;
      background: none;
      border: none;
    }

    .icon {
      width: 32px;
      height: 32px;
      transition: transform 0.1s;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    .sub-menu {
      position: absolute;
      width: 100%;
      @media screen and (min-width: 992px) {
        top: 100%
      }
    }

    :global(.dropdown) {
      position: relative;
    }

    :global(.nav-item-link) {
      padding-right: 0;
    }

    &.wrapped_by_dropdown .sub-menu {
      position: relative;
    }

    &.wrapped_by_dropdown :global(.dropdown) {
      position: relative;
      width: calc(100% - 16px);
      margin-left: 16px;
      border: none;
    }
  }
</style>
