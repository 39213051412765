<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";

  export let on_page = false;
  export let request_store_key: StoresKey;
  const { reverse_router, search_input, T } = getStoresContext(request_store_key);

  $: t = $T;
  $: search_href = $reverse_router.routePurpose("search");

  let value: string = $search_input;

  function search() {
    window.location.href = search_href + "?search=" + value;
  }
</script>

<div class="search__wrapper" class:on_page>
  <div class="search-icon" />
  <input type="text" placeholder={t`search-menu`}
    bind:value
    on:change={search} />
</div>

<style lang="scss">
  .search__wrapper {
    position: relative;
    input,
    input:active,
    input:visited,
    input:focus {
      width: 100vw;
      height: 44px;
      padding: 5px 70px 5px 25px;
      outline: none;
      border: none;
      background: transparent;
      -webkit-appearance: none;
    }
  }

  .on_page {
    margin: 0 auto;

    input,
    input:active,
    input:visited,
    input:focus {
      width: 100%;
      background-color: #fff;
      border: 1px solid #101820;
      border-radius: 22px;
    }
  }

  ::placeholder {
    color: #101820;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #101820;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #101820;
  }

  .search-icon {
    background: url('/theme/mylly2023/images/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 7px;
    right: 30px;
  }
  @media screen and (min-width: 992px) {
    .search__wrapper {
      padding-right: 50px;
      text-align: center;
      position: relative;
      max-width: 300px;
      input,
      input:active,
      input:visited,
      input:focus {
        width: 270px;
        height: 44px;
        padding: 5px 15px 5px 55px;
        border-radius: 22px;
        border: 1px solid #101820;
      }

      &.on_page {
        padding: 0;
      }
    }

    .search-icon {
      background-size: cover;
      width: 16px;
      height: 16px;
      top: 15px;
      left: 30px;
      right: auto;
    }
  }
</style>
