<script>
  /*
   * Borrowed logic from REPL below but transfered it into component
   * https://svelte.dev/repl/4ad71e00c86c47d29806e17f09ff0869?version=3.44.2
   */

  import { createEventDispatcher, onMount } from 'svelte';

  export let stick_to_top = true;

  const dispatch = createEventDispatcher();

  let sticky_sentinel_top;
  let sticky_sentinel_bottom;
  let intersection_observer;

  /** @type {IntersectionObserverCallback} */
  function intersectionCallback(entries) {
    // only observing one item at a time
    const entry = entries[0];

    let is_stuck = false;
    if (!entry.isIntersecting && isValidYPosition(entry)) {
      is_stuck = true;
    }
    setTimeout(() => dispatch('stuck', is_stuck), 75);
  }

  /** @type {(entry: IntersectionObserverEntry) => boolean} */
  function isValidYPosition({ target, boundingClientRect }) {
    if (target === sticky_sentinel_top) {
      return boundingClientRect.y < 0;
    } else {
      return boundingClientRect.y > 0;
    }
  }

  onMount(() => {
    intersection_observer = new IntersectionObserver(intersectionCallback, {});
    return () => intersection_observer.disconnect();
  });

  $: if (intersection_observer) {
    if (stick_to_top) {
      intersection_observer.unobserve(sticky_sentinel_bottom);
      intersection_observer.observe(sticky_sentinel_top);
    } else {
      intersection_observer.unobserve(sticky_sentinel_top);
      intersection_observer.observe(sticky_sentinel_bottom);
    }
  }
</script>

<div class="sticky-sentinel-top" bind:this={sticky_sentinel_top} />
<slot />
<div class="sticky-sentinel-bottom" bind:this={sticky_sentinel_bottom} />

<style>
  .sticky-sentinel-top,
  .sticky-sentinel-bottom {
    position: absolute;
    height: 1px;
  }
</style>
