<!-- @format -->
<script lang="ts">
  import NavItem from './NavItem.svelte';
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { type Alternate } from "~/metadata";
  import { ReverseRouter } from "~/reverse-router";

  export let request_store_key: StoresKey;
  export let toggle_dropdown:any = null;
  export let wrapped_by_dropdown = false;

  const { locale, reverse_router, alternate, site } = getStoresContext(request_store_key);
  
  const language_map : Record<string, {name:string; order:number}>= {
    'fi-FI': { name: 'Suomi', order: 0 },
    'sv-SE': { name: 'Svenska', order: 1 },
    'en-US': { name: 'English', order: 2 }
  };
  let nav_item:any
  let language_defaults: Array<{lang:string; link:string; name:string; order: number}>

  $: if($site && $site?.supportedLanguages) {
    language_defaults = $site.supportedLanguages.map((lang:string) => {
      return {
        lang,
        link: hrefFor(lang,$alternate, $reverse_router),
        ...language_map[lang]
      }
    });
  } 

function hrefFor (
    locale: string, 
    alternate: readonly Alternate[], 
    reverse_router: Readonly<ReverseRouter>
  ) {
    const alt = alternate.find(x => x.locale == locale);
    return alt ? alt.href : reverse_router.findPurposeRoute('home page', locale);
  }

  $: nav_item = {
    id: 0,
    link: null,
    name: 'Language',
    navigationMenuItems: language_defaults?.map(({ lang, name, link}:any) => {
      return {
        id: Math.floor(Math.random() * 100),
        lang,
        link,
        active: lang == $locale,
        name
      };
    })
  };

</script>

  <NavItem {nav_item} {toggle_dropdown} {wrapped_by_dropdown} {request_store_key}/>
