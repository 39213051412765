<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { fly, fade } from 'svelte/transition';
  import { writable } from 'svelte/store';

  import Sticky from "./Sticky.svelte";
  import SearchField from "./SearchField.svelte";
  import BodyContent, { NONE }  from "../BodyContent.svelte";
  import DropdownMenu from "./DropdownMenu.svelte";
  import LangItem from "./LangItem.svelte";
  import OpeningHours from "./OpeningHours.svelte";
  import NavLink from "./NavLink.svelte";
  import NavItem from "./NavItem.svelte";
  import close_icon from '@public/theme/mylly2023/images/nav_close.svg';
  import search_icon from '@public/theme/mylly2023/images/nav_search.svg';
  import menu_icon from '@public/theme/mylly2023/images/nav_menu.svg';
  // import HeaderOneLiner from "./HeaderOneLiner.svelte";

  export let request_store_key: StoresKey;

  const { locale, nav_menus, site_page, reverse_router, site, i18nOf, T, lang } = getStoresContext(request_store_key);

  const dropdown_open = writable(null);

  let is_stuck:any;
  $: toggle_search = $dropdown_open == 'search';
  $: toggle_nav = $dropdown_open == 'nav';

  let home_page_nav_item: any;
  let all_nav_items:any = [];
  let main_nav_items: NavigationMenuItem[];
  let page_active_title:any;
  let nav_item_threshold:any;

  $: t = $T;

  $: home_page_nav_item = $reverse_router.routePurpose("home page");
  $: logo = $site && $site.logo;

  $: dropdownMenu = $nav_menus && $nav_menus.find((nav) => nav.name == "Dropdown navigation");

  $: {
    all_nav_items = (dropdownMenu?.navigationMenuItems || []).filter(
      (item) => !item.excluded_locales?.some((x) => x == $locale)
    );

    nav_item_threshold = navItemThreshold($lang); 
    main_nav_items = all_nav_items.slice(1, nav_item_threshold);
    page_active_title = $site_page ? i18nOf($site_page.title_i18n) : ""; 
  }

  function handleStuck(event:CustomEvent) {
    is_stuck = event.detail;
  }
  function toggle(dropdown:any) {
    if ($dropdown_open == dropdown) $dropdown_open = null;
    else $dropdown_open = dropdown;
  }

  function navItemThreshold(lang: string) {
    return lang == 'fi' ? 5 : 4;
  }

</script>

<!-- <HeaderOneLiner /> -->
<Sticky on:stuck={handleStuck}>
<header
  class:sticking={is_stuck}
  class:item-threshold-4={nav_item_threshold == 4}
  class:item-threshold-5={nav_item_threshold == 5}>

  {#if toggle_search}
    <div id="search-box" transition:fly={{ duration: 200, y: 60, opacity: 0 }}>
      <SearchField {request_store_key} />
    </div>
  {/if}

  <BodyContent padding={NONE}>
    <div id="nav-menu" class:toggle_nav>
      <DropdownMenu {request_store_key} nav_items={all_nav_items}>
        <svelte:fragment slot="append">
            <LangItem {request_store_key} wrapped_by_dropdown={true} />
        </svelte:fragment>
      </DropdownMenu> 
    </div>
    <nav>
      {#if home_page_nav_item}
        <a class="logo-img" href={home_page_nav_item}>
          <img src={logo} alt="" width="147" height="125" />
        </a>
      {/if} 

      <div class="page-title">{page_active_title}</div>

      <div class="extra" in:fly={{ duration: 200, delay: 100 }} out:fade={{ duration: 50 }}>
        <SearchField {request_store_key} />
        <OpeningHours {request_store_key} as_link={true}/>
        <LangItem {request_store_key} toggle_dropdown={dropdown_open} />
      </div>
      <div class="main-items">
        {#each main_nav_items as nav_item}
          <NavItem {nav_item} toggle_dropdown={dropdown_open} {request_store_key}/>
        {:else}
          <NavLink name="&nbsp;" />
        {/each}

        <button
          class="toggle d-lg-none"
          style="padding: 0 14px 0 0;"
          on:click={() => toggle('search')}
          aria-controls="search-box"
          aria-expanded={toggle_search}
          aria-label={t`toggle-search`}>
          <img
            class="icon"
            src={toggle_search ? close_icon.src : search_icon.src}
            alt={t`toggle-search`}
          />
        </button>
        <button
          class="toggle"
          class:hide-desk={all_nav_items.length <= nav_item_threshold}
          on:click={() => toggle('nav')}
          aria-controls="nav-menu"
          aria-expanded={toggle_nav}
          aria-label={t`toggle-navigation`}>
          <img
            class="icon"
            src={toggle_nav ? close_icon.src : menu_icon.src}
            alt={t`toggle-navigation`}
          />
        </button>
      </div>
    </nav>
  </BodyContent>
  </header>
</Sticky>

<style lang="scss">
  header {
    position: fixed;
    bottom: 0;
    bottom: env(safe-area-inset-bottom);
    width: 100vw;
    background-color: #fff;
    z-index: 1000;

    --border-color: #c4c4c4;
  }

  header::after {
    position: absolute;
    content: '';
    width: 100%;
    height: env(safe-area-inset-bottom);
    background-color: #fff;
    z-index: 10;
  }

  nav {
    position: relative;

    display: grid;
    grid-template-columns: 60px auto 120px;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'home active main';

    justify-items: center;
    align-items: center;

    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 20%);

    z-index: 6;
  }

  .logo-img {
    grid-area: home;

    img {
      width: 66px;
      height: 60px;
      padding-left: 10px;
    }
  }

  .page-title {
    grid-area: active;
    width: calc(100vw - 195px);
    padding-left: 1vw;
    text-transform: uppercase;
  }

  .extra {
    display: none;
  }

  .main-items {
    grid-area: main;

    :global(.nav-item) {
      display: none;
    }
  }

  .toggle {
    background: none;
    border: none;

    .icon {
      max-width: 36px;
      max-height: 36px;
    }
  }

  #search-box {
    position: absolute;
    bottom: 100%;
    padding: 8px 0;
    background-color: #ffa400;
    z-index: 4;
  }

  #nav-menu {
    position: relative;
    z-index: 4;

    & :global(.dropdown) {
      --dropdown-border: none;
    }

    & > :global(.dropdown) {
      top: 100%;
      max-height: calc(100vh - 60px);
      padding: 16px;
      overflow: auto;
      transition: transform 0.2s;
    }

    &.toggle_nav > :global(.dropdown) {
      transform: translateY(-100%);
    }
  }

  @media screen and (min-width: 992px) {
    header {
      position: relative; /* For older browsers */
      position: sticky;
      top: 0;
      bottom: unset;
      box-shadow: none;

      --border-color: #101820;

      transition: all 0.2s;
    }

    header::after {
      display: none;
    }

    nav {
      grid-template-columns: 125px auto;
      grid-template-rows: 90px 57px;
      gap: 0px 0px;
      grid-template-areas:
        'home extra'
        'home main';

      justify-items: end;
      box-shadow: none;

      transition: grid-template-rows 0.2s 0.2s;
    }

    #nav-menu {
      position: absolute;
      width: 100%;
      top: 100%;
      right: 0;

      & > :global(.dropdown) {
        width: 330px;
        padding: 0;
        right: 0;
        transform: translateY(-100%);
        --dropdown-border: 1px solid var(--border-color, #101820);
      }

      &.toggle_nav > :global(.dropdown) {
        transform: translateY(0);
      }
    }

    .logo-img img {
      width: 147px;
      height: 125px;
      transition: all 0.2s;
    }

    .page-title,
    /* .toggle:first-of-type, */
    .toggle.hide-desk,
    /* match nav_item_threshold */
    .item-threshold-4 #nav-menu > :global(.dropdown > .item:nth-child(-n+4)), 
    .item-threshold-5 #nav-menu > :global(.dropdown > .item:nth-child(-n+5)),
    #nav-menu > :global(.dropdown > .item:last-of-type) {
      display: none;
    }

    .extra {
      display: flex;
      align-items: center;
      grid-area: extra;

      :global(.sub-menu) {
        top: 100%;
      }
    }

    .main-items {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      :global(.nav-item) {
        display: inline-block;
      }
    }

    .toggle {
      padding: 12px 14px;
      margin-left: 32px;
    }

    .sticking {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 20%);

      .logo-img img {
        height: 85px;
      }

      nav {
        grid-template-rows: 0 100%;
        align-items: end;
      }

      .main-items {
        align-items: end;
      }
    }
  }
</style>
