<!-- @format -->
<script lang="ts">
  import NavItem from './NavItem.svelte';
  import type { StoresKey } from "~/stores";

  export let request_store_key: StoresKey;
  export let nav_items: NavigationMenuItem[] ;
</script>

<ul class="dropdown">
  {#each nav_items as nav_item}
    <li class="item">
      <NavItem {nav_item} wrapped_by_dropdown={true} {request_store_key}/>
    </li>
  {/each}
  {#if $$slots.append}
    <li class="item">
      <slot name="append" />
    </li>
  {/if}
</ul>

<style lang="scss">
  .dropdown {
    --dropdown-border: 1px solid var(--border-color, #101820);
    --item-border: 1px solid var(--border-color, #101820);
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: var(--dropdown-border);
    border-bottom: none;
    z-index: 1000;
  }

  .item {
    color: #101820;
    text-transform: uppercase;
    border-bottom: var(--item-border);
    font-weight: 400;
    letter-spacing: 1px;
    list-style: none;

    :global(a) {
      padding: 12px 20px;
      font-size: 16px;
      text-align: left;
    }

    :global(.item:last-child) {
      border-bottom: none;
    }
  }
</style>
